








































































































































import { defineComponent } from '@vue/composition-api'
import { push, GtmJobOffer } from '@/inc/utils'

export default defineComponent({
  props: {
    content: Array,
  },
  setup(_props, ctx) {
    const onClick = (job, link) => {
      let eventAction

      if (link.label === 'Job Offer') {
        eventAction = 'download'
      }

      if (link.label === 'Contact') {
        eventAction = 'contact'
      }

      if (ctx.root.$router.$$type === 'client') {
        const layer: GtmJobOffer = {
          event: 'job-offer',
          eventCategory: 'job-offer',
          eventAction,
          eventLabel: job.title,
        }

        // DGTM 5.2.3
        push(layer)
      }
    }

    return {
      onClick,
    }
  },
})
